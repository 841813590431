import React, { useContext } from "react";
import { GlobalDataContext } from "../../../context/context";
import { ButtonContent } from "../boton/ButtonContent";
import { BiPhoneCall } from "react-icons/bi";
// import { BsCalendar4Range } from "react-icons/bs";
import Navmenu from "./NavMenu";
import { Link } from "react-router-dom";

const HeaderTwo = ({ btnName }) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <header className="absolute z-[15] w-full bg-[#000]">
      <div className="flex">
        <div className="w-full  md:w-[40%] lg:w-[21%]">
          <img
            src={rpdata?.dbPrincipal?.logo}
            alt="logo"
            loading="lazy"
            className="w-[80%] mx-auto md:ml-16"
          />
        </div>

        <div className="hidden md:flex w-[65%] lg:w-[72%] mx-auto justify-center items-center">
          <div className="flex w-full mx-auto justify-center">
            <div className="hidden w-[80%] text-white lg:flex justify-around">
              <ul className="p-2 flex w-full flex-wrap justify-center gap-5 items-center">
                {rpdata?.dbPrincipal?.phones?.map((item, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={`tel:+1${item.phone}`}
                        className="flex items-center"
                      >
                        <BiPhoneCall fontSize={35} className="mx-1" />

                        <span className="flex flex-col md:pt-[5px]">
                          <span className="md:pl-3 mt-[-5px]">Phone</span>
                          <span className="md:pl-3 font-bold md:text-[18px] text-[14px]">
                            {item.phone}
                          </span>
                        </span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* <div className="hidden w-[30%] text-white  bp lg:flex mx-auto justify-center items-center">
              <div className="w-[15%] self-center mr-3">
                <BsCalendar4Range fontSize={35} />
              </div>
              <div>
                <p>{rpdata?.dbPrincipal?.workdays?.[0]?.day}</p>
                <h5 className="text-[21px]">
                  {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                </h5>
              </div>
            </div> */}

            {/* <div className='hidden w-[30%] text-white  lg:flex mx-auto justify-center'>
                            <div className='w-[15%] self-center mr-3'>
                                <BsClockHistory fontSize={35} />
                            </div>
                            <div>
                                <p className='-mb-3'>
                                    {rpdata?.dbPrincipal?.workdays?.[1]?.day}
                                </p>
                                <h5 className='text-[21px]'>
                                    {rpdata?.dbPrincipal?.workHours?.[1]?.hour}
                                </h5>
                            </div>
                        </div> */}
          </div>
        </div>
        <div className="hidden bg2 md:flex w-[35%] lg:w-[18%] mx-auto justify-center items-center  bg-center bg-contain bg-no-repeat">
          <Link
            to={`/${btnName === "view all services" ? "services" : "contact"}`}
          >
            <span className="capitalize text-white text-[20px] font-semibold">
              {btnName ? btnName : "FREE ESTIMATE"}
            </span>
          </Link>
        </div>
      </div>

      <div className="bg-white px-3 py-4">
        <div className="flex justify-between md:justify-center items-center">
          <nav>
            <Navmenu />
          </nav>
          <div className="block md:hidden">
            <ButtonContent />
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderTwo;
